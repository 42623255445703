<template>
    <div class="box">
        <div class="banar">
            <el-carousel :interval="5000" arrow="never" loop>
                <el-carousel-item v-for="(item, index) in Banner" :key="index">
                    <img :src="item.imgUrl" alt="" @click="banar(item)" class="ban">
                </el-carousel-item>
            </el-carousel>
        </div>

        <div class="demo-split-panea">
            <div class="demo-split-pane">
                <div class="new">新闻中心</div>
                <div class="zhongxin" @click="num = 1">工作动态</div>
                <div class="yenei" @click="num = 2">业内新闻</div>

            </div>

            <div class="fiexbox">
                <div class="onez" v-show="num == 1" v-for="(item, index) in zhongxinews " :key="index + '1'">
                    <div class="gdd">
                        <div class="gggg" @click="gengduio()">更多</div>
                        <img src="../../assets/img/index/Combined Shape@2x.png" class="imgaa" alt="">
                    </div>
                    <div class="contbox">
                        <div class="dian"></div>
                        <div class="biao" @click="leix(item.id,item.newsMode)">{{ item.newsTitle }}</div>
                        <div class="time">{{ item.createTime }}</div>
                    </div>

                </div>
                <div class="onez" v-show="num == 2" v-for="(item, index) in yeyunew" :key="index + '2'">
                    <div class="gdd">
                        <div class="gggg" @click="gengduio1()">更多</div>
                        <img src="../../assets/img/index/Combined Shape@2x.png" class="imgaa" alt="">
                    </div>
                    <div class="contboxx">
                        <div class="dian"></div>
                        <div class="biao" @click="leixx(item.id,item.newsMode)">{{ item.newsTitle }}</div>
                        <div class="time">{{ item.createTime }}</div>
                    </div>

                </div>
            </div>

        </div>
        <!-- 专题 -->
        <div class="zhuanti" v-if="!zhunti">
            <!-- <div  v-for="(item,index) in zhuanti" :key="index">
                    <img :src="item.imgUrl" alt="" class="st1" @click="projectone(item.linkAddr)">
             </div> -->
            <el-carousel indicator-position="none" :interval="5000" height="115px" loop>
                <el-carousel-item v-for="(item, index) in zhuanti" :key="index" class="banne">
                    <img :src="item.imgUrl" alt="" class="imgur"
                        @click="projectone(item.linkAddr, item.type, item.id, item.thumbnailUrl)">
                </el-carousel-item>
            </el-carousel>

            <!-- <Carousel autoplay loop :autoplay-speed="5000" arrow="never">
                        <CarouselItem v-for="(item,index) in zhuanti" :key="index" class="banne">
                              <img :src="item.imgUrl" alt="" class="imgur" @click="projectone(item.linkAddr,item.type,item.id,item.thumbnailUrl)">
                        </CarouselItem>
                    </Carousel> -->
        </div>
        <div v-else></div>

        <div class="kapian">
            <Row>
                <Col span="11">
                <Card dis-hover class="dik">
                    <div slot="title" class="tit">
                        <div class="tongz">公告通知</div>
                        <div class="gd">
                            <div class="gg" @click="duod()">更多</div>
                            <img src="../../assets/img/index/Combined Shape@2x.png" class="imga" alt="">
                        </div>
                    </div>
                    <div class="demo-split-paneq" v-for="(item, index) in gonggao" :key="index">
                        <div class="diann"></div>
                        <div class="biaoo" @click="leixj(item.id,item.newsMode)">{{ item.newsTitle }}</div>
                        <div class="timer">{{ item.createTime }}</div>
                    </div>
                </Card>
                </Col>
                <Col span="11" offset="1">
                <Card dis-hover class="diaa">
                    <div slot="title" class="tit">
                        <div class="tongz">政策法规</div>
                        <div class="gd">
                            <div class="gg" @click="dduo()">更多</div>
                            <img src="../../assets/img/index/Combined Shape@2x.png" class="imga" alt="">
                        </div>
                    </div>
                    <div class="demo-split-paneq" v-for="(item, index) in wenjian" :key="index">
                        <div class="diannx"></div>
                        <div class="biaoot"
                            @click="leixjjs( item.newsMode, item.id)">
                            {{ item.newsTitle }}</div>
                        <div class="timerw">{{ item.createTime }}</div>
                    </div>
                </Card>
                </Col>
            </Row>
        </div>
        <div class="ying">
            <div class="xitongg">
                <img src="../../assets/img/xinx/Combined Shape.png" class="tibiao" alt="">
                <div class="ziti">系统应用</div>
            </div>
            <div class="st">
                <div class="aa" v-for="(item, index) in tupian" :key="index">
                    <img :src="item.imgUrl" alt="" class="st1" @click="xitong(item.applyUrl)">
                </div>

            </div>

        </div>
        <div class="ying">
            <div class="xitong">
                <img src="../../assets/img/xinx/Combined Shape.png" class="tibiao" alt="">
                <div class="ziti">友情链接</div>
            </div>
            <div class="xila">
                <div>
                    <Select v-model="model1" class="caid" placeholder="省级以上相关部门网站">
                        <Option v-for="(item, index) in cityList1" :value="item.blogrollName" :key="index"
                            @click.native="waibu(item.url)">{{ item.blogrollName }}</Option>
                    </Select>

                </div>
                <div>
                    <Select v-model="model2" class="caid" placeholder="全省各市药监网站">
                        <Option v-for="(item, index) in cityList2" :value="item.blogrollName" :key="index"
                            @click.native="waibu(item.url)">{{ item.blogrollName }}</Option>
                    </Select>
                </div>
                <div>
                    <Select v-model="model3" class="caid" placeholder="相关单位网站">
                        <Option v-for="(item, index) in cityList3" :value="item.blogrollName" :key="index"
                            @click.native="waibu(item.url)">{{ item.blogrollName }}</Option>
                    </Select>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { incrReadingNum, queryBlogroll, queryApplyUrln, queryPageNews, queryBanner, querySubject, querySubjectPlate } from "../../woke/xinx"
import { formatDate } from '../../commm/time.js'
export default {
    data() {
        return {
            split3: 0.5,
            split4: 0.3,
            Banner: [],
            youqinglianjie1: [],
            tupian: [],
            gonggao: [],
            zhongxinews: [],
            yeyunew: [],
            zhuanti: [],
            wenjian: [],
            num: 1,
            model1: '',
            model2: '',
            model3: '',
            cityList1: [],
            cityList2: [],
            cityList3: [],
            Arr1: [],
            Arr2: [],
            Arr3: [],
            Arr4: [],
            Arr5: [],
            Arr6: [],
            Arr7: [],
            Arr8: [],
            curPage: 1,
            maxPage: 10,
            id: "",
            zhunti: false,

        }
    },
    created() {
        this.queryBlogroll1()
        this.queryBlogroll2()
        this.queryBlogroll3()
        this.queryApplyUrln()
        this.queryPageNews()
        this.queryPageNewsaa()
        this.queryPageNewsqwe()
        this.queryPageNewsyeyu()
        this.queryBanner()
        this.querySubject()

    },
    mounted() {

    },

    methods: {
        xitong(e) {
            window.open(e, "_blank")
        },
        waibu(e) {
            window.open(e, "_blank")
        },
        gengduio() {
            this.$router.push("/news")
        },
        gengduio1() {
            this.$router.push("/industry")
        },
        duod() {
            this.$router.push("/announcement")
        },
        dduo() {
            this.$router.push("/policy")
        },
        leix(id,newsMode ) {
            //    console.log(newsTitle,createTime,newsResource,content,accessoryUrl,newsMode,accessoryName);
            incrReadingNum({
                id: id,
                type: 1
            }).then(res => {
            })
            if (newsMode == 1) {
                //   console.log(11);
                this.$router.push({
                    name: "newsdetail",
                    params: {
                        id: id,
                    }
                });
            } else if (newsMode == 2) {
                window.open(content, "_blank")
            }
        },
        leixx(id,newsMode ) {
            incrReadingNum({
                id: id,
                type: 1
            }).then(res => {
            })
            if (newsMode == 1) {
                //   console.log(11);
                this.$router.push({
                    name: "yenei",
                    params: {
                        id: id,
                    }
                });
            } else if (newsMode == 2) {
                window.open(content, "_blank")
            }
        },
        leixjjs(newsMode, id) {
            //    console.log(newsTitle,createTime,newsResource,content,accessoryUrl,newsMode,accessoryName);
            incrReadingNum({
                id: id,
                type: 1
            }).then(res => {
            })
            if (newsMode == 1) {
                this.$router.push({
                    name: "falv",
                    params: {
                        id: id
                    }
                });
            } else if (newsMode == 2) {
                window.open(content, "_blank")
            }
        },
        leixj(id) {
            incrReadingNum({
                id: id,
                type: 1
            }).then(res => {
            })
            console.log(id);
            this.$router.push({
                name: "ggdetail",
                params: {
                    id: id
                }
            });

        },
        banar(e) {
            console.log(e, "heh");
            if (e.type == 1) {
                if (e.modeName == "通知公告") {
                    queryPageNews({
                        curPage: 1,
                        maxPage: 999999,
                        isStick: 3,
                        type: 3
                    }).then(res => {
                        this.Arr1 = res.data.data.data
                        this.Arr1.forEach((item, i) => {
                            console.log(this.Arr1[i].id == e.modeNum);
                            if (this.Arr1[i].id == e.modeNum) {
                                this.$router.push({
                                    name: "ggdetail",
                                    params: {
                                        id: item.id
                                    }
                                })
                            }
                        })

                    })
                } else if (e.modeName == "政策法规") {
                    queryPageNews({
                        curPage: 1,
                        maxPage: 999999,
                        isStick: 1,
                        type: 4
                    }).then(res => {
                        this.Arr2 = res.data.data.data
                        this.Arr2.forEach((item, i) => {
                            if (this.Arr2[i].id == e.modeNum) {
                                this.$router.push({
                                    name: "falv",
                                    params: {
                                        id: item.id
                                    }
                                })
                            }
                        })
                    })
                } else if (e.modeName == "工作动态") {
                    queryPageNews({
                        curPage: 1,
                        maxPage: 100000,
                        isStick: 3,
                        type: 1
                    }).then(res => {
                        // console.error(res.data.data, "中心新闻");
                        this.Arr3 = res.data.data.data;
                        this.Arr3.forEach((item, i) => {
                            if (this.Arr3[i].id == e.modeNum) {
                                this.$router.push({
                                    name: "newsdetail",
                                    params: {
                                        id: item.id
                                    }
                                });
                            }
                        })
                    })
                } else if (e.modeName == "业内新闻") {
                    queryPageNews({
                        curPage: 1,
                        maxPage: 100000,
                        isStick: 3,
                        type: 2
                    }).then(res => {
                        // console.error(res.data.data, "中心新闻");
                        this.Arr4 = res.data.data.data;
                        this.Arr4.forEach((item, i) => {
                            if (this.Arr4[i].id == e.modeNum) {
                                this.$router.push({
                                    name: "yenei",
                                    params: {
                                        id: item.id
                                    }
                                });
                            }
                        })
                    })
                } else if (e.modeName == "专题内容") {
                    querySubject().then(res => {
                        // console.log(res,"查询专题");
                        this.Arr5 = res.data.data
                        this.Arr5.forEach((item, i) => {
                            if (this.Arr5[i].id == e.modeNum) {
                                this.$router.push({
                                    name: "projectone",
                                    query: {
                                        id: item.id,
                                        img: item.thumbnailUrl,
                                    }
                                });
                            }
                        })
                    })
                }
            } else if (e.type == 2) {
                window.open(e.outLink, "_blank")
            }
        },
        projectone(linkAddr, type, id, thumbnailUrl) {
            // console.log( linkAddr,type,id);
            if (type == 1) {
                querySubjectPlate(id).then(res => {
                    console.log(res, "专题下内容");
                    if (res.data.data.length == 0) {
                        //    this.$Message.error("该专题下暂无内容")
                    } else {
                        this.$router.push({
                            name: "projectone",
                            query: {
                                id: id,
                                img: thumbnailUrl,
                            }
                        });
                    }
                })

            } else if (type == 2) {
                //   console.log(11);
                if (linkAddr == "" || linkAddr == undefined || linkAddr == null) {
                    //    this.$Message.error("该专题下暂无内容")
                } else {
                    window.open(linkAddr, "_blank")
                }
            }
        },
        //轮播图
        queryBanner() {
            queryBanner().then(res => {
                console.log(res.data.data, "轮播图");
                this.Banner = res.data.data
                this.Banner.reverse()
            })
        },
        //查询友情链接1
        queryBlogroll1() {
            queryBlogroll({
                flag: 1,
            }).then(res => {
                // console.log(res.data.data,"查询友情链接1");
                this.Arr6 = res.data.data
                function compare(property) {
                    return function (a, b) {
                        var value1 = a[property];
                        var value2 = b[property];
                        return value1 - value2;
                    }

                }
                this.cityList1 = this.Arr6.sort(compare('sort'))
            })
        },
        //查询友情链接2
        queryBlogroll2() {
            queryBlogroll({
                flag: 2,
            }).then(res => {
                // console.log(res.data.data,"查询友情链接2");
                this.Arr7 = res.data.data
                function compare(property) {
                    return function (a, b) {
                        var value1 = a[property];
                        var value2 = b[property];
                        return value1 - value2;
                    }

                }
                this.cityList2 = this.Arr7.sort(compare('sort'))
            })
        },
        //查询友情链接3
        queryBlogroll3() {
            queryBlogroll({
                flag: 3,
            }).then(res => {
                // console.log(res.data.data,"查询友情链接3");
                this.Arr8 = res.data.data
                function compare(property) {
                    return function (a, b) {
                        var value1 = a[property];
                        var value2 = b[property];
                        return value1 - value2;
                    }

                }
                this.cityList3 = this.Arr8.sort(compare('sort'))
            })
        },
        //查询应用
        queryApplyUrln() {
            queryApplyUrln().then(res => {
                // console.log(res,"查询应用");
                this.tupian = res.data.data
            })
        },
        //公告
        queryPageNews() {
            queryPageNews({
                curPage: this.curPage,
                maxPage: 5,
                isStick: 3,
                type: 3
            }).then(res => {
                console.log(res, "公告");
                this.gonggao = res.data.data.data

            })
        },
        //法规
        queryPageNewsaa() {
            queryPageNews({
                curPage: this.curPage,
                maxPage: 5,
                isStick: 1,
                type: 4
            }).then(res => {
                console.log(res.data.data, "法规");
                this.wenjian = res.data.data.data

            })
        },
        //中心新闻
        queryPageNewsqwe() {
            queryPageNews({
                curPage: this.curPage,
                maxPage: 10,
                isStick: 3,
                type: 1
            }).then(res => {
                // console.error(res.data.data, "中心新闻");
                this.zhongxinews = res.data.data.data;

            })
        },
        //业余
        queryPageNewsyeyu() {
            queryPageNews({
                curPage: this.curPage,
                maxPage: 10,
                isStick: 1,
                type: 2
            }).then(res => {
                console.log(res.data.data, "业余");
                this.yeyunew = res.data.data.data
                // this.yeyunew = this.yeyunew.sort((a,b)=>{
                //     return  a.createTime > b.createTime ? -1: 1;
                // });


            })

        },
        //查询专题
        querySubject() {
            querySubject().then(res => {
                console.log(res, "查询专题");
                this.zhuanti = res.data.data
                if (this.zhuanti.length == 0) { // 没有数据
                    this.zhunti = true
                } else {// 有数据
                    this.zhunti = false
                }

            })
        },




    },
    filters: {
        /* 时间格式自定义 只需把字符串里面的改成自己所需的格式*/
        formatDate(time) {
            var date = new Date(time);
            return formatDate(date, 'yyyy-MM-dd');
        },

    },


}
</script>
<style lang="less" scoped>
.box {
    width: 1903px;
    background: #F3F8FDFF;
    min-height: 100vh;
    margin-top: 10px;
}

.banar {
    width: 1200px;
    height: 340px;
    margin-left: 360px;
    margin-top: 50px;
    margin-bottom: 20px;
}

/deep/.el-carousel__container {
    width: 1200px;
    height: 340px;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}

/deep/.ivu-carousel-dots {
    display: none;
}

.demo-split-pane {
    width: 1200px;
    display: flex;
    justify-content: left;
}

.ban {
    width: 1200px;
    height: 340px;
}

.fiexbox {
    width: 1200px;
    // height: 170px;
    display: inline-block;
    height: 200px;
    font-size: 0;
    // writing-mode: vertical-lr;
}

.onez {
    width: 600px;
    display: inline-block;
    height: 40px;
    line-height: 30px;
    // writing-mode: horizontal-tb;
}

.contbox {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.contboxx {
    width: 100%;

    display: flex;
    justify-content: space-around;
}

.demo-split-paneq {
    display: flex;
    justify-content: space-between;
}

.demo-split-panea {
    width: 1200px;
    margin-left: 360px;
    margin-bottom: 20px;
    position: relative;
    // margin-top: 20px;
    background: #FFFFFF;
    height: 270px;
}

.dik {
    width: 595px;
    height: 268px;
}

.diaa {
    width: 592px;
    height: 268px;
    margin-left: 10px;

}

.new {
    width: 155px;
    height: 60px;
    background-image: url(../../assets/img/index/1.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    line-height: 60px;
    z-index: 10;
    cursor: pointer;

}

.zhongxin {
    width: 240px;
    height: 60px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    background-image: url(../../assets/img/index/3.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    line-height: 60px;
    margin-left: -80px;
    text-align: center;
    z-index: 5;
    cursor: pointer;
}

.yenei {
    width: 240px;
    height: 60px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 60px;
    background-image: url(../../assets/img/index/2.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    margin-left: -80px;
    text-align: center;
    cursor: pointer;
}

.dian {
    width: 10px;
    height: 10px;
    background: #51ACFF;
    border-radius: 50%;
    margin-left: 12px;
    margin-top: 17px;
}

.diann {
    width: 10px;
    height: 10px;
    background: #51ACFF;
    border-radius: 50%;
    margin-left: 15px;
    margin-top: 17px;
}

.diannx {
    width: 10px;
    height: 10px;
    background: #51ACFF;
    border-radius: 50%;
    margin-left: 5px;
    margin-top: 17px;
}

/deep/ .ivu-split-trigger-horizontal {
    display: none;
}

.biao {
    width: 350px;
    height: 25px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: -27px;
    cursor: pointer;
    margin-top: 10px;
}

.biaoo {
    width: 350px;
    height: 25px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: -30px;
    cursor: pointer;
    margin-top: 10px;
}

.biaoot {
    width: 350px;
    height: 25px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: -25px;
    cursor: pointer;
    margin-top: 10px;
}

.time {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #999999;
    line-height: 19px;
    margin-top: 10px;

}

.timer {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #999999;
    line-height: 19px;
    margin-top: 10px;
}

.timerw {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #999999;
    line-height: 19px;
    margin-top: 10px;
    margin-right: 5px;
}

/deep/ .ivu-split-trigger-vertical {
    display: none;
}

.st {
    width: 1200px;
    display: flex;
    justify-content: flex-start;
    margin-left: 360px;
    color: black;
    margin-top: -10px;
    flex-wrap: wrap;
}

.aa:nth-child(3n+1) {
    margin-left: 0px;
}

.aa {
    margin-left: 15px;
}

.st1 {
    width: 390px;
    height: 115px;
}

.kapian {
    margin-top: 20px;
    width: 1200px;
    margin-left: 360px;
}

.tongz {
    width: 155px;
    height: 60px;
    background-image: url(../../assets/img/index/1.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    line-height: 60px;
    z-index: 10;
    cursor: pointer;
    margin-left: -16px;
    margin-top: -16px;
}

/deep/.ivu-card-head {
    height: 59px;

}

.gg {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #2C3B5B;
    line-height: 19px;
    letter-spacing: 1px;
    cursor: pointer;
    /* margin-top: 2px; */
}

.gggg {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 19px;
    letter-spacing: 1px;
    cursor: pointer;
    opacity: 0.2;
}

.gd {
    width: 200px;
    display: flex;
    justify-content: right;
    margin-right: 20px;
}

.gdd {
    width: 200px;
    display: flex;
    justify-content: right;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.tit {
    display: flex;
    justify-content: space-between;
}

.imga {
    width: 10px;
    height: 12px;
    margin-top: 4px;
}

.imgaa {
    width: 10px;
    height: 12px;
    margin-top: 4px;
    opacity: 0.2;
}

.xitongg {
    width: 100px;
    display: flex;
    justify-content: left;
    margin-left: 360px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.xitong {
    width: 100px;
    display: flex;
    justify-content: left;
    margin-left: 360px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.xila {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin-left: 360px;
    margin-bottom: 20px;
    margin-top: -10px;
}

.ying {
    width: 1200px;
}

.tibiao {
    width: 10px;
    height: 16px;
}

.ziti {
    font-size: 18px;
    color: #2C3B5BFF;
    margin-top: -4px;
    margin-left: 5px;
}

.zhuanti {
    width: 1200px;
    margin-left: 360px;
}

.banne {
    width: 1200px;
    height: 115px;
}

.imgur {
    width: 1200px;
    height: 115px;
    cursor: pointer;
}

.caid {
    width: 390px;
}

/deep/ .ivu-carousel-dots {
    margin-top: 50px;
}

/deep/ .caid {
    border: 1px black solid;
    border-radius: 5px;
}
</style>